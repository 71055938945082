import React from "react";
import Logo from "../../assets/images/Thirty3-logo.png";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    let route;
    switch (path) {
      case "Home":
        route = "/";
        break;
      case "about":
        route = "/about";
        break;
      case "About Us":
        route = "/about";
        break;
      case "Work":
        route = "/projects";
        break;
      default:
      // code block
    }
    if(path!=="Contact Us")
    {
      navigate(route);
    }
  };
  return (
    <footer className="p-3">
      <center>
        <img onClick={()=>handleNavigation("Home")} className="footer-logo" width="100%" src={Logo} />
      </center>
      <div className="footer-row">
        <div className="footer-link is-1">01</div>
        <a href="" onClick={()=>handleNavigation("Home")} aria-current="page" className="footer-link w--current">
          index
        </a>
        <a href="mailto:info@thirty3mkt.com" className="footer-link">
          email
        </a>
        <a href="" className="footer-link is-4">
          Back to Top
        </a>
      </div>
      <div className="footer-row">
        <div className="footer-link is-1">02</div>
        <a href="" onClick={()=>handleNavigation("Work")} aria-current="page" className="footer-link w--current">
          Projects
        </a>
        <a href="https://www.instagram.com/thirty3mkt" target="_blank" className="footer-link">
          Instagram
        </a>
        <a href="https://www.linkedin.com/company/thirty3-marketing/" target="_blank" className="footer-link is-4">
          Linkedin
        </a>
      </div>
    </footer>
  );
};
export default Footer;
