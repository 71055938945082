import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import HomeBanner from "../../assets/images/HomeBanner.jpg";
import Slide1 from "../../assets/images/slides/slide1.jpg";
import Slide2 from "../../assets/images/slides/slide2.jpg";
import Slide3 from "../../assets/images/slides/slide3.jpg";
import Slide4 from "../../assets/images/slides/slide4.jpg";
import Slide5 from "../../assets/images/slides/slide5.jpg";
import Slide6 from "../../assets/images/slides/slide6.jpg";
import Slider from "react-slick";
import { Fade } from "react-awesome-reveal";
const Home = () => {
  return (
    <>
      <Header />
      <main class="container-fluid">
        <section class="home-hero_wrapper mb-3">
          <h1 class="home-hero-text text-size-huge">
            We partner with visionary brands to create immersive digital
            experiences.
          </h1>
          <img class="home-hero-picture" src={HomeBanner} />
        </section>
        <section>
          <Slider {...settings}>
            <img class="home-hero-picture" src={Slide1} />
            <img class="home-hero-picture" src={Slide2} />
            <img class="home-hero-picture" src={Slide3} />
            <img class="home-hero-picture" src={Slide4} />
          </Slider>
          {/* <video
            className="video"
            id="f3935bcc-0244-57b9-ffa2-9037c00b0e97-video"
            autoPlay={true}
            loop={true}
            style={{
              backgroundImage:
                'url("https://uploads-ssl.webflow.com/6261c485a7f8794f2702289b/64060b221999e5ba296fdb4f_1600X1200 - MARCH 2023-poster-00001.jpg")',
            }}
            muted=""
            playsInline=""
            data-wf-ignore="true"
            data-object-fit="cover"
          >
            <source
              src="https://uploads-ssl.webflow.com/6261c485a7f8794f2702289b/64060b221999e5ba296fdb4f_1600X1200 - MARCH 2023-transcode.mp4"
              data-wf-ignore="true"
            />
            <source
              src="https://uploads-ssl.webflow.com/6261c485a7f8794f2702289b/64060b221999e5ba296fdb4f_1600X1200 - MARCH 2023-transcode.webm"
              data-wf-ignore="true"
            />
          </video> */}
        </section>
        <section className="home-hero_wrapper mb-3">
          <h2 className="text-size-medium m-15">Services</h2>
          <div className="service-wrapper">
            <div className="service-item">
              <div className="font-mono text-size-xsmall">01</div>
              <div className="heading-size-xlarge">Graphic Design</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">02</div>
              <div className="heading-size-xlarge">Website Design</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">03</div>
              <div className="heading-size-xlarge">Social Media</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">04</div>
              <div className="heading-size-xlarge">Digital Marketing</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">05</div>
              <div className="heading-size-xlarge">Content Creation</div>
            </div>
          </div>
          <div className="divider" />
        </section>
        <section className="home-hero_wrapper mb-3">
          <h2 className="text-size-medium m-15">Selected Work</h2>
          <div
            // style={{
            //   backgroundImage:
            //     'url("https://uploads-ssl.webflow.com/6265b099776abb7ac273476d/62782c494102c572eb4d4ac3_COVER-FAFAFA.png")',
            // }}
            style={{
              backgroundImage:
                `url(${Slide4})`,
            }}
            className="project-cover selected-work"
          />
          <div
            style={{
              backgroundImage:
                `url(${Slide5})`,
            }}
            className="project-cover selected-work"
          />
          <div
            style={{
              backgroundImage:
                `url(${Slide6})`,
            }}
            className="project-cover selected-work"
          />
          <div className="divider" />
        </section>
        <Footer />
      </main>
    </>
  );
};
export default Home;


var settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1
};