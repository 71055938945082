import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ProjectShow from "../../components/project-show";
import ArrowHeadImage from "../../assets/images/HomeBanner.jpg";
import SevLaserImage from "../../assets/images/slides/slide2.jpg";
import CaviarTruffleImage from "../../assets/images/slides/slide3.jpg";
import PressImage from "../../assets/images/slides/slide7.jpg";
import LTDImage from "../../assets/images/slides/slide8.jpg";
const SelectedWork = () => {
  return (
    <>
      <Header />
      <main>
        <div className="heropage-project">
          <div class="divider-white"></div>
          <div class="text-size-huge p-5 text-white">Works</div>
        </div>
        <div className="p-3">
          {project_list.map((project) => (
            <ProjectShow project={project} />
          ))}
        </div>
        <Footer />
      </main>
    </>
  );
};
export default SelectedWork;

const project_list = [
  {
    title: "ArrowHead",
    image: ArrowHeadImage,
  },
  {
    title: "Sevlaser",
    image: SevLaserImage,
  },
  {
    title: "Caviar & Truffle",
    image: CaviarTruffleImage,
  },
  {
    title: "Press Pilate",
    image: PressImage,
  },
  {
    title: "LTD Residence",
    image: LTDImage,
  },
  {
    title: "Curbstand",
    image: "https://uploads-ssl.webflow.com/6265b099776abb7ac273476d/63a734440bd1f462461a0613_DR-MAIN.jpg",
  },
];
