import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import "./assets/css/stylesheet.css";

function App() {
  return (
    <div class="container-fluid p-0 m-0">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
