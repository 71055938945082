import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/Thirty3-logo.png";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
const meetingUrl = "https://calendly.com/thirty3mkt/30min";
const Header = () => {
  const navigate = useNavigate();
  const [menu_open, setMenuOpen] = useState(false);
  useEffect(() => {
    if (menu_open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [menu_open]);
  const handleNavigation = (path) => {
    let route;
    switch (path) {
      case "Home":
        route = "/";
        break;
      case "about":
        route = "/about";
        break;
      case "About Us":
        route = "/about";
        break;
      case "Work":
        route = "/projects";
        break;
      case "Contact Us":
        window.open(meetingUrl, '_blank', 'noreferrer');
        // window.location.replace(meetingUrl);
        break;
      default:
      // code block
    }
    if(path!=="Contact Us")
    {
      navigate(route);
    }
  };
  return (
    <>
      <header className="d-flex justify-content-between align-items-center header p-2">
        <a
          onClick={() => handleNavigation("Home")}
          style={{ paddingLeft: 0 }}
          href=""
          className="navlink"
        >
          <img className="logo" src={Logo} />
        </a>
        <a
          href=""
          onClick={() => handleNavigation("about")}
          className="navlink mobile-hide d-sm-none d-md-block"
        >
          Information
        </a>
        <a
          href=""
          onClick={() => handleNavigation("Work")}
          className="navlink mobile-hide"
        >
          Selected Work
        </a>
        <div className="header-menu mobile-hide">
          <a className="book-call" href={meetingUrl} target="_blank">
            <span>BOOK A CALL</span>
          </a>
          <button
            onClick={() => setMenuOpen(!menu_open)}
            className="text-button"
            id="menu-opener"
          >
            <div className="toggle-menu">
              <div className="text-size-small">MENU</div>
              <img
                src="https://uploads-ssl.webflow.com/6261c485a7f8794f2702289b/640619a868e81c1c92be77b6_toggle-menu.svg"
                loading="lazy"
                className="toggle-icon ms-2"
              />
            </div>
          </button>
        </div>
        <button
          onClick={() => setMenuOpen(!menu_open)}
          className="text-button desktop-hide menu-mobile-button"
        >
          <div className="toggle-menu d-flex">
            <div className="text-size-small">MENU</div>
            <img
              src="https://uploads-ssl.webflow.com/6261c485a7f8794f2702289b/640619a868e81c1c92be77b6_toggle-menu.svg"
              loading="lazy"
              className="toggle-icon ms-2"
            />
          </div>
        </button>
      </header>
      <Fade delay={1e3} cascade damping={1e-1}>
        <div
          style={{ display: menu_open ? "block" : "none" }}
          className={`header-overlay`}
        >
          <a
            href="#"
            onClick={() => setMenuOpen(!menu_open)}
            className="menu-link-small"
          >
            <p className="text-move">Close</p>
          </a>
          {["Home", "About Us", "Work", "Contact Us"].map((item) => (
            <a
              key={`Header Items ${item}`}
              href=""
              onClick={() => handleNavigation(item)}
              className="menu-link is-1 w-inline-block w--current"
            >
              <p className="text-move">{item}</p>
            </a>
          ))}
        </div>
      </Fade>
    </>
  );
};
export default Header;
