import React, { useState } from "react";
const ProjectShow = ({ project }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div
        onMouseEnter={() => setHover(!hover)}
        onMouseLeave={() => setHover(!hover)}
        className="position-relative projecut-show-container"
      >
        <div class="heading-xxlarge project-list-title">{project.title}</div>
        <div class="text-size-small">2023</div>
        <div class="cover__wrapper">
          <div
            style={{
                backgroundImage:`url(${project.image})`,
              transform:
                "translate3d(0px, 0vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              opacity: hover ? 1 : 0,
            }}
            className="cover__item"
          />
        </div>
      </div>
    </>
  );
};
export default ProjectShow;
