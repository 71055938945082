import { createBrowserRouter } from "react-router-dom";
import Home from "../screens/home";
import AboutUs from "../screens/about";
import SelectedWork from "../screens/work";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/about",
    element: <AboutUs/>,
  },
  {
    path: "/projects",
    element: <SelectedWork/>,
  },
]);
