import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
const AboutUs = () => {
  return (
    <>
      <Header />
      <main>
        <section className="home-hero_wrapper mb-3">
          <h1 className="text-size-huge" style={{ color: "#aaa" }}>
            Contact
          </h1>
          <h1 className="text-size-huge">
            If you're interested in our work and services, drop us an email and
            we'll be in touch shortly. info@thirty3marketing.com
          </h1>
        </section>
        <div className="divider" />
        <section className="home-hero_wrapper mb-3">
          <h2 className="text-size-medium m-15">Services</h2>
          <div className="service-wrapper">
            <div className="service-item">
              <div className="font-mono text-size-xsmall">01</div>
              <div className="heading-size-xlarge">Graphic Design</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">02</div>
              <div className="heading-size-xlarge">Website Design</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">03</div>
              <div className="heading-size-xlarge">Social Media</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">04</div>
              <div className="heading-size-xlarge">Digital Marketing</div>
            </div>
            <div className="service-item">
              <div className="font-mono text-size-xsmall">05</div>
              <div className="heading-size-xlarge">Content Creation</div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};
export default AboutUs;
